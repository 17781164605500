<template>
	<div class="auth-container">
		<div class="card-container">
			<!-- Register v1 -->
			<b-card class="mb-0 custom-card">
				<b-link :to="{ name: '/' }" class="brand-logo">
					<h2 class="brand-text text-primary text-center">Emoty</h2>
				</b-link>
				<b-card-title class="mb-2 d-flex justify-content-center">
					Adventure starts here 🚀
				</b-card-title>
				<validation-observer ref="vendorRegisterValidation">
					<b-form @submit.prevent>
						<b-row>
							<b-col cols="12">
								<b-form-group label="Identification" label-for="v-id">
									<validation-provider
										#default="{ errors }"
										name="identification number"
										rules="required"
									>
										<b-form-input
											id="v-id"
											v-model="identificationCode"
											type="number"
											placeholder="Identification Number"
											@change="checkForId"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
								<b-form-checkbox v-model="isIndividualEntrepreneur">
									Is Individual Entrepreneur
								</b-form-checkbox>
							</b-col>

							<!-- email -->
							<b-col cols="12">
								<b-form-group label="Email" label-for="v-email">
									<validation-provider
										#default="{ errors }"
										name="email"
										rules="required||email"
									>
										<b-form-input
											id="v-email"
											v-model="userEmail"
											type="email"
											placeholder="Email"
											@change="checkForEmail"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider></b-form-group
								>
							</b-col>

							<!-- mobile -->
							<b-col cols="12">
								<b-form-group label="Mobile" label-for="v-mobile">
									<validation-provider
										#default="{ errors }"
										name="mobile number"
										rules="required"
									>
										<b-form-input
											id="v-mobile"
											v-model="mobile"
											type="number"
											placeholder="Mobile"
											@change="checkForMobile"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>

							<!-- password -->
							<b-col cols="12">
								<b-form-group label-for="account-password" label="Password">
									<validation-provider
										#default="{ errors }"
										name="password"
										rules="required"
									>
										<b-input-group class="input-group-merge">
											<b-form-input
												id="account-password"
												v-model="password"
												:type="passwordFieldType"
												name="password"
												placeholder="Password" />
											<b-input-group-append is-text>
												<feather-icon
													:icon="passwordToggleIcon"
													class="cursor-pointer"
													@click="togglePasswordVisibility"
												/> </b-input-group-append
										></b-input-group>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider></b-form-group
								>
							</b-col>

							<!-- submit and reset -->
							<b-col cols="12 d-flex justify-content-end">
								<b-button
									v-ripple.400="'rgba(186, 191, 199, 0.15)'"
									type="back"
									variant="outline-secondary"
									@click="goToLoginPage"
								>
									Back
								</b-button>
								<b-button
									v-ripple.400="'rgba(255, 255, 255, 0.15)'"
									type="submit"
									variant="primary"
									class="ml-1"
									@click.prevent="validationForm"
								>
									Submit
								</b-button>
							</b-col>
						</b-row>
					</b-form>
				</validation-observer>
			</b-card>
		</div>
	</div>
</template>

<script>
import {
	BCard,
	BLink,
	BCardTitle,
	BRow,
	BCol,
	BFormCheckbox,
	BFormGroup,
	BFormInput,
	BForm,
	BButton,
	BInputGroup,
	BInputGroupAppend,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { mapActions, mapState } from 'vuex';

import Ripple from 'vue-ripple-directive';

export default {
	components: {
		BCard,
		BLink,
		BCardTitle,
		BRow,
		BCol,
		BFormGroup,
		BFormInput,
		BForm,
		BFormCheckbox,
		BButton,
		ValidationProvider,
		ValidationObserver,
		BInputGroup,
		BInputGroupAppend,
	},
	directives: {
		Ripple,
	},
	mixins: [togglePasswordVisibility],
	data() {
		return {
			privacyPopupIsOpen: false,
			identificationCode: '',
			isIndividualEntrepreneur: false,
			userEmail: '',
			mobile: '',
			password: '',
			passwordFieldType: 'password',
			required,
			email,
		};
	},
	computed: {
		...mapState('vendorRegistration', ['errors']),
		passwordToggleIcon() {
			return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
		},
	},
	methods: {
		...mapActions('vendorRegistration', [
			'vendorRegistration',
			'mobileCheck',
			'emailCheck',
			'identificationCodeCheck',
			'clearErrors',
		]),
		checkForId() {
			this.identificationCodeCheck(this.identificationCode);
			if (this.errors.idCode) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Identification Number Is Invalid !',
						icon: 'BellIcon',
						variant: 'danger',
					},
				});
			}
		},
		checkForEmail() {
			this.emailCheck(this.userEmail);
			if (this.errors.email) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Email Is Invalid !',
						icon: 'BellIcon',
						variant: 'danger',
					},
				});
			}
		},
		checkForMobile() {
			this.mobileCheck(this.mobile);
			if (this.errors.mobile) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Mobile Number Is Invalid !',
						icon: 'BellIcon',
						variant: 'danger',
					},
				});
			}
		},
		checkEmail() {
			// eslint-disable-next-line no-useless-escape
			const emailRFCRegex = new RegExp(
				// eslint-disable-next-line no-useless-escape
				"^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
			);

			console.log(emailRFCRegex.test('aaa@gmail.com'));
		},
		allAreNull() {
			return this.errors.every((element) => element === null);
		},
		goToLoginPage() {
			this.$router.push('/login');
		},
		validationForm() {
			this.$refs.vendorRegisterValidation.validate().then((success) => {
				if (success) {
					if (this.allAreNull) {
						this.vendorRegistration({
							identificationCode: this.identificationCode,
							email: this.userEmail,
							isIndividualEntrepreneur: this.isIndividualEntrepreneur,
							mobile: this.mobile,
							password: this.password,
						}).then((response) => {
							if (!response.data.error) {
								this.goToLoginPage();
							}
						});
						this.clearErrors();
					} else {
						console.log('there is some errors');
					}
				}
			});
		},
	},
};
// 599563658
</script>
<style scoped>
.privacyWrapper {
	display: flex;
	flex-direction: row;
}
.agree-text {
	margin-right: 4px;
}
.custom-card {
	max-width: 560px;
}
.auth-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
</style>
